import React from "react";
import MaskedInput from "react-text-mask";
import { MASK_CEP, MASK_ZIP_CODE, removeMask } from "../../../helper/mask";
import { Select, Form, Row, Col, Input } from "antd";

import InputOrSearch from "./Input-search";
import services from "./../services";
import useSecurityContext from "../../../component/Security/useSecurityContext";
import { Permissions } from "../../../Constantes/permissions.constants";

const BRASIL = "BRASIL";
const Endereco = ({ nome, form, status }) => {
  const [opcoesUf, setOpcoesUf] = React.useState([]);
  const [opcoesPais, setOpcoesPais] = React.useState([]);
  const [opcoesCidade, setOpcoesCidade] = React.useState([]);
  const { hasPermission } = useSecurityContext();
  const [brasilSelecionado, setBrasilSelecionado] = React.useState(true);
  const [cepEncontrado, setCepEncontrado] = React.useState(false);
  const [ufSelecionada, setUfSelecionada] = React.useState(false);
  const [cidadeSelecionada, setCidadeSelecionada] = React.useState(false);

  const getPermissaoPais = () =>
    hasPermission({ hasAnyRole: Permissions.RECADASTRAMENTO.TODOS_PAIS });

  const carregarOpcoesUf = () => {
    services
      .obterUF("")
      .then((response) => response?.data)
      .then((data) =>
        data?.map(({ id, sigla }) => ({ value: sigla, label: sigla }))
      )
      .then(setOpcoesUf);
  };

  const carregarOpcoesCidade = (uf) => {
    services
      .obterCidades(uf)
      .then((response) => response.data.content)
      .then((data) =>
        data.map(({ id, nome }) => ({ value: nome, label: nome }))
      )
      .then(setOpcoesCidade);
  };

  const carregarOpcoesPais = () => {
    if (getPermissaoPais()) {
      services
        .obterPais()
        .then((response) => response.data)
        .then((data) => data.map(({ nome }) => ({ value: nome, label: nome })))
        .then(setOpcoesPais)
        .catch(console.error);
    } else {
      setOpcoesPais([{ value: BRASIL, label: BRASIL }]);
    }
  };

  const handleConsultaCep = (e) => {
    const cep = removeMask(e.target.value);
    if (brasilSelecionado && cep.length === 8) {
      carregarCEP(cep).catch(() => {
        form.setFieldsValue({
          [nome]: {
            logradouro: "",
            uf: "",
            cidade: "",
            bairro: { nome: "" },
          },
        });
        setCepEncontrado(false);
        setUfSelecionada(false);
        setCidadeSelecionada(false);
      });
    }
  };

  const carregarCEP = (valor) => {
    return services.getEndereco(valor).then((endereco) => {
      setCepEncontrado(true);
      const logradouro = `${endereco.tipo} ${endereco.logradouro}`;
      form.setFieldsValue({
        [nome]: {
          logradouro: logradouro,
          uf: endereco.uf,
          cidade: endereco.cidade,
          bairro: { nome: endereco.bairro },
        },
      });
    });
  };

  const controleInicialCEP = (valor) => {
    if (valor !== undefined && valor.length === 8) {
      carregarCEP(valor).catch(() => {
        setCepEncontrado(false);
        carregarOpcoesCidade(form.getFieldValue([nome, "uf"]));
        setUfSelecionada(true);
        setCidadeSelecionada(true);
      });
    }
  };

  const controleInicialPais = (valor) => {
    if (valor === BRASIL) setBrasilSelecionado(true);
    else setBrasilSelecionado(false);
  };

  const resetarCamposEndereco = () => {
    form.setFieldsValue({
      [nome]: {
        cep: "",
        logradouro: "",
        complemento: "",
        uf: "",
        cidade: "",
        bairro: "",
      },
    });
  };

  const handleAlteracaoPais = (value) => {
    if (value === undefined || value === BRASIL) setBrasilSelecionado(true);
    else setBrasilSelecionado(false);
    resetarCamposEndereco();
    setCepEncontrado(true);
  };

  const handleAlteracaoUF = (value) => {
    if (brasilSelecionado) {
      setUfSelecionada(true);
      carregarOpcoesCidade(value);
    }
  };

  const handleAlteracaoCidade = (value) => {
    if (brasilSelecionado) {
      setCidadeSelecionada(true);
    }
  };

  React.useEffect(() => {
    controleInicialPais(form.getFieldValue([nome, "pais"]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  React.useEffect(() => {
    if (brasilSelecionado)
      controleInicialCEP(form.getFieldValue([nome, "cep"]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brasilSelecionado]);

  React.useEffect(() => {
    carregarOpcoesUf();
    carregarOpcoesPais();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Row>
        <Col span={4}>
          <Form.Item
            name={[nome, "pais"]}
            label="País"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Selecione..."
              options={opcoesPais}
              showSearch
              onChange={handleAlteracaoPais}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name={[nome, "cep"]}
            label={brasilSelecionado ? "CEP" : "ZIP Code"}
            rules={[
              { required: true },
              { validator: brasilSelecionado ? Endereco.Validator : null },
            ]}
          >
            <MaskedInput
              type="text"
              guide={false}
              autoComplete="off"
              mask={brasilSelecionado ? MASK_CEP : MASK_ZIP_CODE}
              className="ant-input"
              onBlur={handleConsultaCep}
            />
          </Form.Item>
        </Col>
        <Col span={14}>
          <Form.Item
            name={[nome, "logradouro"]}
            label="Endereço"
            preserve={true}
            rules={[{ required: true }, { type: "string", max: 255 }]}
          >
            <Input
              type="text"
              maxLength="300"
              disabled={cepEncontrado && brasilSelecionado}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item
            name={[nome, "complemento"]}
            label="Complemento"
            rules={[{ required: true }, { max: 255 }]}
          >
            <Input type="text" />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item
            name={[nome, "uf"]}
            label="UF"
            rules={[{ required: true }, { max: 2 }]}
          >
            <InputOrSearch
              options={opcoesUf}
              disabled={cepEncontrado && brasilSelecionado}
              onChange={handleAlteracaoUF}
              showSearch
              showInput={!brasilSelecionado}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            name={[nome, "cidade"]}
            label="Cidade"
            rules={[{ required: true }]}
          >
            <InputOrSearch
              options={opcoesCidade}
              disabled={(cepEncontrado || !ufSelecionada) && brasilSelecionado}
              onChange={handleAlteracaoCidade}
              showSearch
              showInput={!brasilSelecionado}
            />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            name={[nome, "bairro", "nome"]}
            label="Bairro"
            rules={[{ required: true }, { max: 72 }]}
          >
            <Input
              disabled={
                (cepEncontrado || !cidadeSelecionada) && brasilSelecionado
              }
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

Endereco.Validator = (_, value = "") =>
  value.replace(/\D/g, "").length < 8 && value.replace(/\D/g, "").length > 0
    ? Promise.reject(new Error("CEP Incorreto"))
    : Promise.resolve();

export default Endereco;
