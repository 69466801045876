import React, { useEffect, useState } from "react";
import { Secured } from "../../component";
import { Permissions } from "../../Constantes/permissions.constants";
import { Message } from "../../Constantes/message.constants";
import services from "./services";
import { PageContainer } from "@ant-design/pro-layout";
import Desbloqueio from "./Desbloqueio"
import moment from "moment";
import {
  SaveFilled,
  CheckOutlined,
  ArrowLeftOutlined,
  WarningOutlined,
  StopFilled
} from "@ant-design/icons";
import "./style.less";
import {
  Form,
  Col,
  Input,
  Card,
  Row,
  Button,
  Modal,
  TimePicker,
  notification,
} from "antd";


const Agendamento = () => {
  const [form] = Form.useForm();
  const [activeSaveButton, setActiveSaveButton] = useState(false);
  const [show, setShow] = useState(false);
  const formatHour = "HH:mm";
  const initialValuesSchema = {
    data: "",
    hora: "",
  };
  const [valuesSchema, setValuesSchema] = useState(initialValuesSchema);
  const [oldValuesBlock, setOldValuesBlock] = useState(initialValuesSchema);

  const handleBlockRegister = () => {
  form.validateFields(["data", "hora"]).then(() => {
      setShow(true);
      setValuesSchema(form.getFieldsValue());
    });
  };

  const handleBlockCancelRegister = () => {
    form.setFieldsValue(oldValuesBlock)
    setValuesSchema(oldValuesBlock)
    setActiveSaveButton(false)
};

  useEffect(() => {
    services
      .getAgendamento()
      .then((response) => {
        response.data.hora = moment(response.data.hora, formatHour);
        setValuesSchema(response.data);
        setOldValuesBlock(response.data)
        setActiveSaveButton(false)
      })
      .catch(console.error);
  }, []);

  const registerBlock = () => {
    valuesSchema.hora = moment(valuesSchema.hora).format("HH:mm:ss");
    services
      .salvar(valuesSchema)
      .then((response) => {
        setShow(false);
        setActiveSaveButton(false);
        setOldValuesBlock(form.getFieldsValue());
        notification.success({
          message: 'Bloqueio com sucesso!',
          description: Message.MSG000_REALIZADA_COM_SUCESSO,
          duration: 5,
        });
      })
      .catch((error) => console.log);
  };

  React.useEffect(() => {
    if (valuesSchema.data === "" || valuesSchema.hora === "") {
      setActiveSaveButton(true);
    }
    form.setFieldsValue(valuesSchema);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, valuesSchema]);

  return (
    <>
 
        <PageContainer>
        <Secured
        hasAnyRole={Permissions.RECADASTRAMENTO.TELA}
        isShowErro403={true}
        >
          <h1>Bloqueios e Desbloqueios</h1>
          <Form layout="vertical" form={form}>
          <Card title="Bloqueios e Suspensões">
          
              <Row>
                <Col sm={24} md={10} lg={7}>
                  <Form.Item
                    label="Dia (Ex: 30)"
                    name="data"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                      () => ({
                        async validator(_, value) {
                          if (value < 1 || value > 31) {
                            throw new Error("Por favor, digite 01 ao 31!");
                          }

                          return value;
                        },
                      }),
                    ]}
                  >
                    <Input
                      maxLength={2}
                      placeholder="Digite o dia"
                      onChange={() => setActiveSaveButton(true)}
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={10} lg={7}>
                  <Form.Item
                    label="Hora (Ex: 15:30)"
                    name="hora"
                    rules={[
                      {
                        required: true,
                        message: "Por favor, digite a hora!",
                      },
                    ]}
                  >
                    <TimePicker
                      format={formatHour}
                      onChange={() => setActiveSaveButton(true)}
                      allowClear
                      placeholder="Digite a hora"
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={5} lg={4} className="align-col-button">
                  <Button
                    type="primary"
                    icon={<SaveFilled />}
                    onClick={() => handleBlockRegister()}
                    disabled={!activeSaveButton}
                  >
                    {activeSaveButton ? "Salvar" : "Salvo"}
                  </Button>

                  <Button
                    type="danger"
                    icon={<StopFilled />}
                    onClick={() => handleBlockCancelRegister()}
                    disabled={!activeSaveButton}
                    hidden={!activeSaveButton}
                    style={{marginLeft: 10}}
                  >
                  Cancelar
                  </Button>

                </Col>
                
              </Row>
          </Card>
          <Modal
            visible={show}
            onOk={registerBlock}
            onCancel={() => setShow(false)}
            title={Message.TITLE_CONFIRMACAO}
            okText="Alterar"
            okButtonProps={{
              type: "primary",
              icon: <CheckOutlined />,
              style: { background: "#043a0c" },
            }}
            cancelButtonProps={{
              icon: <ArrowLeftOutlined />,
            }}
          >
            <Row justify="center">
              <Col>
                <div className="align-center">
                  <WarningOutlined className="alert-icon" />
                </div>
                <p className="text-center">{Message.MSG013_ALTERAR_REGISTRO}</p>
                
                <p className="text-center">Dia: {valuesSchema.data} Hora: {moment(valuesSchema.hora).format("HH:mm:ss")}</p>
                
              </Col>
            </Row>
          </Modal>
          </Form>
          </Secured>
        
        <Desbloqueio/>
        </PageContainer>
   
    </>
  );
};

export default Agendamento;
