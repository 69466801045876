import { intervalToDuration, getYear } from "date-fns";
import { applyMaskAnoMesDia } from "./mask";
import moment from "moment";
const { maskBr } = require("js-brasil");

export const isEmpty = (value) => {
  if (value === null || value === undefined) return true;
  return value.length === 0;
};

export const isEmptyBlank = (value) => {
  return isEmpty(value) || value === "";
};

export const isNotEmpty = (value) => {
  return !isEmpty(value);
};

export const blankValue = (value) => {
  return isNotEmpty(value) ? value : "";
};

export const nullValue = (valor) => {
  return isNotEmpty(valor) ? valor : null;
};

export const copyObject = (item) => Object.assign({}, item);

export const calcularDiferenciaEmAnosMesDias = (dataInicial, dataFinal) => {
  return intervalToDuration({
    start: dataInicial,
    end: dataFinal,
  });
};

export const getURL = (url, tipo, mainHome) => {
  return mainHome === tipo ? "/" : url;
};

export const getYearsPeriod = (date) => {
  const fimPeriodo = getYear(date);
  const inicioPeriodo = fimPeriodo - 11;
  return { inicioPeriodo, fimPeriodo };
};

export const getDateOfddMMYYYY = (dateString) => {
  let data = applyMaskAnoMesDia(dateString);
  return new Date(data + " 00:00:00");
};

export const getDateOfYYYYMMdd = (dateString) => {
  return new Date(dateString + " 00:00:00");
};

export const imprimir = (data) => {
  const url = URL.createObjectURL(data);
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  a.href = url;
  a.target = "_blank";
  a.click();
};

export const imprimirDoc = (data, filename) => {

  const url = URL.createObjectURL(data);
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  a.href = url;
  a.download = filename;
  a.target = "_blank";
  a.click();
};



export const removeAcento = (texto) => {
  texto = texto.toLowerCase();
  texto = texto.replace(new RegExp("[ÁÀÂÃ]", "gi"), "a");
  texto = texto.replace(new RegExp("[ÉÈÊ]", "gi"), "e");
  texto = texto.replace(new RegExp("[ÍÌÎ]", "gi"), "i");
  texto = texto.replace(new RegExp("[ÓÒÔÕ]", "gi"), "o");
  texto = texto.replace(new RegExp("[ÚÙÛ]", "gi"), "u");
  texto = texto.replace(new RegExp("[Ç]", "gi"), "c");
  return texto;
};

export const diffDateInYear = (dateEnd, dateIni) => {
  return moment.duration(dateEnd.diff(dateIni)).asYears();
};

export const formatarData = (valor) =>
  valor ? moment(valor, "YYYY-MM-DD") : null;

export function convertMoment(pDate, pFormat) {
  if (pDate != null) return moment(pDate, pFormat);
  else return null;
}

export function formatDate(pDate, pFormat) {
  if (pDate != null) return moment(pDate).format(pFormat);
  else return "";
}

export function formatDateLessTime(pDate, pFormat) {
  if (pDate != null) {
      return moment(pDate).format('DD-MM-YYYY'); // Formato sem hora
  } else {
      return "";
  }
}

export function formatCpf(cpf) {
  const formattedCpf = maskBr.cpf(cpf);

  return formattedCpf;
}
