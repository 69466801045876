import axios from "axios";
import config from "../../config";

import {
  imprimirRelatorio,
} from "../../helper/util";

const BASE_API = config.BASE_API;

const salvar = (parametro) => {
  return axios.post(BASE_API + "bloqueios/agendamento/", parametro);
};

const getAgendamento = () => axios.get(BASE_API + "bloqueios/agendamento");

const getReport = async (params) => {
  debugger
  return axios
    .get(`${BASE_API}bloqueios/desbloqueados/relatorio`, {
      params,
      responseType: "blob",
    })
    .then((resp) => resp.data)
    .catch((error) => {
      if (error.response) {
        const mensagemErro = error.response.data?.message || "Erro desconhecido.";
        return Promise.reject(mensagemErro);
      } else {
        return Promise.reject("Erro ao gerar relatório: Problema de conexão.");
      }
    });
};

const getReportDocx = async (params) => {
      const url = `${BASE_API}bloqueios/desbloqueados/relatorio-word`;
    
      return axios
        .get(`${BASE_API}bloqueios/desbloqueados/relatorio-word`, {
          params,
          responseType: "blob",
        })
        .then((resp) => resp.data)
        .catch((error) => {
          if (error.response) {
            const mensagemErro = error.response.data?.message || "Erro desconhecido.";
            return Promise.reject(mensagemErro);
          } else {
            return Promise.reject("Erro ao gerar relatório: Problema de conexão.");
          }
        });
    };


    const sendEmail = async (params) => {
      try {
        const response = await axios.post(
          `${BASE_API}bloqueios/desbloqueados/enviar-email`,null,
          {params}
        );
        return response;
      } catch (error) {
        if (error.response) {
          const mensagemErro = error.response.data?.message || "Erro desconhecido.";
          return Promise.reject(mensagemErro); 
        } else if (error.request) {
          return Promise.reject("Erro ao gerar relatório e Enviar E-mail: Problema de conexão.");
        } else {
          return Promise.reject("Erro desconhecido.");
        }
      }
    };
    
    
  
const getUnlockDates = () => {
  return {
    dataInicio: "01/02/2002",
    dataFim: "01/03/2005",
  };
};


export default {
  salvar,
  getAgendamento,
  getUnlockDates,
  getReport,
  sendEmail,
  getReportDocx,
};
