import React from "react";
import { Col, Row, Form, Input, Radio, DatePicker } from "antd";
import { removeMask } from "../../../helper/mask";
import moment from "moment";
import services from "../services";
import InputCPF from "./Input-CPF";
import { diffDateInYear } from "../../../helper/util";

const YEAR_REQUER_REPRESENTANTE = 16;

const formatarData = (valor) => (valor ? moment(valor, "YYYY-MM-DD") : null);

const mapPessoa = ({ pais = "BRASIL", nascimento, ...rest }) => ({
  ...rest,
  pais,
  nascimento: formatarData(nascimento),
});

const mapRepresentanteLegal = ({ pessoa, tokenDocumento, ...rest }) => ({
  ...rest,
  pessoa: mapPessoa(pessoa),
  tokenDocumento,
  arquivo: tokenDocumento
    ? [
        {
          uid: "-1",
          name: "Comprovante Representante Legal.pdf",
          type: "application/pdf",
          status: "done",
          token: tokenDocumento,
        },
      ]
    : undefined,
});

const mapCertidao = (
  { livro = "", folha = "", ...certidao },
  certidaoToken
) => ({
  ...certidao,
  livro: `${livro}`,
  folha: `${folha}`,
  certidaoToken,
  arquivo: certidaoToken
    ? [
        {
          uid: "-1",
          name: "Comprovante Certidão.pdf",
          type: "application/pdf",
          status: "done",
          token: certidaoToken,
        },
      ]
    : undefined,
});

const criarPessoaVazia = () => {
  return {
    nome: "",
    sexo: "",
    nascimento: "",
    pais: "BRASIL",
    cep: "",
    logradouro: "",
    complemento: "",
    uf: "",
    cidade: "",
    bairro: "",
    telefone1: "",
  };
};

const IdentificacaoPessoa = ({
  nome,
  camposAdicionais = true,
  desabilitarCampos = true,
  desabilitarCPF = false,
  ocultarCampos = false,
  handleValoresCPF,
  pesquisarRepresentante = false,
  onClear,
  onHandleRequerRepresentante,
}) => {
  const carregarCPF = (valor) => {
    const cpf = removeMask(valor);
    if (cpf && cpf.length === 11) {
      if (!pesquisarRepresentante) {
        services
          .obterCPF(cpf)
          .then((result) => result.data)
          .then((dados) =>
            IdentificacaoPessoa.processarDados(dados, handleValoresCPF)
          );
      } else {
        services
          .obterRepresentante(cpf)
          .then((result) => result.data)
          .then((dados) => {
            dados.nascimento = formatarData(dados.nascimento);
            handleValoresCPF({ ...criarPessoaVazia(), ...dados });
          });
      }
    }
  };

  const handlePesquisarCPF = (valor) => {
    carregarCPF(valor);
  };

  const handleControleRepresentante = (valor) => {
    if (camposAdicionais) {
      return onHandleRequerRepresentante(
        IdentificacaoPessoa.checandoDiferencaEntreDatas(valor)
      );
    }
  };

  return (
    <>
      <Row>
        <Col span={4}>
          <Form.Item
            name={[nome, "cpf"]}
            label="CPF"
            rules={[{ required: true }, { validator: InputCPF.Validator }]}
          >
            <InputCPF
              onSearch={handlePesquisarCPF}
              onClear={onClear}
              readOnly={desabilitarCPF}
            />
          </Form.Item>
        </Col>
        <Col span={10} hidden={ocultarCampos}>
          <Form.Item
            name={[nome, "nome"]}
            label="Nome"
            rules={[{ required: true }]}
          >
            <Input disabled={desabilitarCampos} />
          </Form.Item>
        </Col>
        <Col span={6} hidden={ocultarCampos}>
          <Form.Item
            name={[nome, "sexo"]}
            label="Sexo"
            rules={[{ required: true }]}
          >
            <Radio.Group disabled={desabilitarCampos}>
              <Radio value="F">Feminino</Radio>
              <Radio value="M">Masculino</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={4} hidden={ocultarCampos}>
          <Form.Item
            name={[nome, "nascimento"]}
            label="Data de Nascimento"
            rules={[{ required: true }]}
          >
            <DatePicker
              style={{ width: "100%" }}
              disabled={desabilitarCampos}
              onChange={handleControleRepresentante}
            />
          </Form.Item>
        </Col>
      </Row>
      {!(ocultarCampos || !camposAdicionais) && (
        <Row>
          <Col span={12}>
            <Form.Item
              name={[nome, "mae"]}
              label="Nome da Mãe"
              rules={[{ required: true }]}
            >
              <Input disabled={desabilitarCampos} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={[nome, "pai"]}
              label="Nome do Pai"
              rules={[{ required: true }]}
            >
              <Input disabled={desabilitarCampos} />
            </Form.Item>
          </Col>
        </Row>
      )}
    </>
  );
};

IdentificacaoPessoa.checandoDiferencaEntreDatas = (valor) =>
  diffDateInYear(moment(), valor) < YEAR_REQUER_REPRESENTANTE;
IdentificacaoPessoa.processarDados = (dados, handleValoresCPF) => {
  dados.pessoa = mapPessoa(dados.pessoa);
  if (dados.representanteLegal)
    dados.representanteLegal = mapRepresentanteLegal(dados.representanteLegal);
  if (dados.certidao)
    dados.certidao = mapCertidao(dados.certidao, dados.certidaoToken);
  if (!dados.certidao) dados["certidao"] = {};
  console.log(dados);
  handleValoresCPF(dados);
};

export default IdentificacaoPessoa;
