import axios from "axios";
import config from "../config";

const BASE_API = config.BASE_API;


const getReports = (params) => {
  return axios.get(BASE_API + "recadastramento/filter", {
    params
  });
};

const visualizarRelatorioCompleto = async (params) => {
  try {
      const response = await axios.get(`${BASE_API}relatorios/censo-feito`, {
          params,
          responseType: "blob"
      });

      return response.data;
  } catch (error) {
      console.error("Erro ao obter o relatório:", error);
      throw error; // Relança o erro para ser tratado no componente
  }
};

const downloadRelatorioCsvCenso = async (params) => {
  try {
    const response = await axios.get(`${BASE_API}relatorios/censo-feito-csv`, {
      params,responseType: "blob"
    });

    if (response && response.data) {
      const blob = response.data;
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "relatorio_censo.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Erro ao obter dados para o CSV.");
    }
  } catch (error) {
    console.error("Erro ao gerar CSV:", error);
  }
};



export default {
  getReports,visualizarRelatorioCompleto,downloadRelatorioCsvCenso
};
