import React, { useMemo, useState, useEffect } from "react";
import "./indexStyle.css";
import { Table, Card, Form, Select, DatePicker, Tag, Button } from "antd";
import { PageContainer } from "@ant-design/pro-layout";
import { QueryFilter } from "@ant-design/pro-form";
import "react-dual-listbox/lib/react-dual-listbox.css";
import services from "../services";
import "react-dual-listbox/lib/react-dual-listbox.css";
import { Permissions } from "../../Constantes/permissions.constants";
import { formatDate, formatCpf } from "../../helper/util";
import { Secured } from "../../component";
import moment from "moment";
import { notification } from 'antd';
import { message } from "antd";


const RelatorioCensoIniciado = () => {

    const status = {
        0: "Iniciado",
        1: "Concluìdo",
        2: "Retificado",
      };

    const [filterValues, setFilterValues] = useState();

    const [isDownloading, setIsDownloading] = useState(false);

    const handleButtonClick = (props, type) => {
        if (props.form) {
            props.form.submit();
        }
    
        if (type === 'pdf') {
            handlePrintReport(props.form.getFieldsValue());
        } else if (type === 'csv') {
            handleDownloadCsv(props.form.getFieldsValue());
        }
    };
    

    const handlePrintReport = async (values) => {
        if (isDownloading) {
            alert("O download está em andamento. Aguarde até que termine.");
            return; 
        }

        try {
            let { dataInicio, dataFim,status } = values || {};

            dataInicio = dataInicio ? moment(dataInicio).format('YYYY-MM-DD') : null;
            dataFim = dataFim ? moment(dataFim).format('YYYY-MM-DD') : null;

            const params = { dataInicio, dataFim,status};

            if(dataInicio == null || dataInicio == undefined){
                return;
            }

            setIsDownloading(true);

            const response = await services.visualizarRelatorioCompleto(params);

            if (!response) {
                throw new Error("Resposta vazia do servidor");
            }

            const contentType = response.type || "";

            if (contentType.includes("application/json")) {
                const errorText = await response.text();
                const errorJson = JSON.parse(errorText);
                console.error("Erro ao gerar relatório:", errorJson);
                alert(errorJson.mensagem || "Erro ao gerar relatório.");
                return;
            }

            // Criar um link para baixar o PDF
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "censos_iniciados.pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();

        } catch (error) {
            console.error("Erro inesperado ao gerar relatório:", error);
            alert("Erro inesperado ao gerar relatório.");
        } finally {
            setIsDownloading(false);
        }
    };
    
    const handleDownloadCsv = async (values) => {
        if (!values || !values.dataInicio) {
            console.error("Período inicial é obrigatório.");
            return;
        }

        let { dataInicio, dataFim,status } = values || {};

        dataInicio = dataInicio ? moment(dataInicio).format('YYYY-MM-DD') : null;
        dataFim = dataFim ? moment(dataFim).format('YYYY-MM-DD') : null;

        const params = { dataInicio, dataFim,status};

        setIsDownloading(true);

        try {
            const response =  await services.downloadRelatorioCsvCenso(params);
    
            console.log("Relatório gerado e baixado com sucesso.");
        } catch (error) {
            console.error("Erro ao gerar o relatório:", error);
        }finally {
            setIsDownloading(false);
        }
    };
    
    
    
    return (
        <>
            <Secured hasAnyRole={Permissions.RELATORIOS.TELA} isShowErro403={true}>
                <PageContainer>
                <h1>Relatório Censo(s)</h1>
                </PageContainer>
            </Secured>
            <Card
                title="Parâmetros do Relatório"
                style={{
                fontSize: "20px",
                marginBottom: "10px",
                position: "relative",
                }}
            >
                <QueryFilter
                    onFinish={(values) => handleButtonClick(values, 'pdf')} // Adicionando ação para PDF
                    onReset={() => setFilterValues({ page: 0, size: 10 })}  
                    layout="vertical"
                    defaultCollapsed
                    className="filtro"
                    submitter={{
                        render: (props, dom) => (
                            <>
                                <div style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',  
                                    justifyContent: 'flex-start',
                                    gap: '10px',
                                    width: '100%',     
                                }}>
                                    {dom[0]} {/* Botão de limpar */}
                                    <Button type="primary" onClick={() => handleButtonClick(props, 'pdf')} style={{ flexShrink: 0 }}>
                                        Download Pdf
                                    </Button>

                                    <Button type="primary" onClick={() => handleButtonClick(props, 'csv')} style={{
                                        flexShrink: 0,
                                        backgroundColor: '#4CAF50',
                                        borderColor: '#4CAF50',
                            
                        }}>
                                        Download Excel
                                    </Button>
                                </div>
                            </>
                        ),
                    }}
                >


                <Form.Item
                    label="Período Inicial:"
                    shouldUpdate={(prevValues, curValues) => {
                    return (
                        prevValues.dataInicio !== curValues.dataInicio ||
                        prevValues.valueEnd !== curValues.valueEnd
                    );
                    }}
                >
                    {({ getFieldValue }) => {
                    const startDatePreenchido0 = getFieldValue("dataInicio");

                    const endDatePreenchido0 = getFieldValue("dataFim");

                    return (
                        <Form.Item
                        name="dataInicio"
                        dependencies={["dataFim"]}
                        rules={[
                            {
                            required: endDatePreenchido0 && true,
                            message: "Por Favor, insira data",
                            },
                            {
                            validator: async () => {
                                if (startDatePreenchido0 == null || startDatePreenchido0 == "") {
                                return Promise.reject(
                                    new Error("Período inicial é obrigatório")
                                );
                                }
                            },
                            },
                        ]}
                        >
                        <DatePicker
                            name="dataFinalVigencia"
                            placeholder="Digite a data"
                            allowClear
                            format="DD/MM/YYYY"
                        />
                        </Form.Item>
                    );
                    }}
                </Form.Item>


                <Form.Item
                    label="Periodo Final:"
                    shouldUpdate={(prevValues, curValues) => {
                    return (
                        prevValues.dataInicio !== curValues.dataInicio ||
                        prevValues.dataFim !== curValues.dataFim
                    );
                    }}
                >
                    {({ getFieldValue,setFieldsValue}) => {
                    const startDatePreenchido0 = getFieldValue("dataInicio");
                    const endDatePreenchido0 = getFieldValue("dataFim");

                    return (
                        <Form.Item
                        name="dataFim"
                        dependencies={["dataInicio"]}
                        rules={[
                            {
                            validator: async () => {
                                if (endDatePreenchido0) {
                                if (endDatePreenchido0 < startDatePreenchido0) {
                                    setFieldsValue({ dataFim: null });
                                    return Promise.reject(
                                    new Error(
                                        "Data final e menor que data inicial."
                                    )
                                    );
                                }
                                }
                            },
                            },
                        ]}
                        >
                        <DatePicker
                            disabledDate={(d) => d.isBefore(startDatePreenchido0)}
                        />
                        </Form.Item>
                    );
                    }}
                </Form.Item>

                <Form.Item label="Status do Censo:" name="status">
                    <Select
                        placeholder="Selecione..."
                        allowClear={true}
                        options={Object.keys(status).map((key) => ({
                        label: status[key],
                        value: status[key],
                        }))}
                    />
                </Form.Item>
                
                </QueryFilter>

            </Card>
        </>
  );

};

export default RelatorioCensoIniciado;