import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import MainHomeContext from "../../state/Home/Context";
import MenuContext from "../../state/Menu/Context";
import * as MenuActions from "../../state/Menu/actions";
import { Secured } from "../Security";
import { Permissions } from "../../Constantes/permissions.constants";

import "./style.scss";
import pinStyles from "./style.module.scss";
import { getURL } from "../../helper/util";

const Menu = () => {
  const { menu, menuDispatch } = useContext(MenuContext);
  const { mainHome } = useContext(MainHomeContext);

  const handlePin = (e) => {
    menuDispatch(MenuActions.changePinned(!menu));
    e.preventDefault();
  };

  return (
    <nav className="" role="navigation">
      <div className={[menu ? pinStyles.unpined : null, "sidebar "].join(" ")}>
        <ul className="nav flex-column top-control  d-block d-sm-block d-md-block d-lg-none">
          <li className="nav-item">
            <a className="nav-link" onClick={handlePin} href="/#">
              <i className="fas fa-bars" />
              Menu
            </a>
          </li>
        </ul>
        <ul className="nav flex-column mediun-control">
          <li className="nav-item">
            <Secured hasAnyRole={Permissions.RECADASTRAMENTO.MENU}>
              <NavLink
                className="nav-link"
                exact
                to="/"
                activeClassName="active"
              >
                <i className="fas fa-user-edit" />
                Recadastramento<span className="sr-only">Página Atual</span>
              </NavLink>
            </Secured>
          </li>
          <Secured hasAnyRole={Permissions.RELATORIOS.MENU}>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="relatoriosDropdown">
                  <i className="fas fa-clipboard" /> Relatórios
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <NavLink className="dropdown-item" to={getURL("/relatoriogerencial", "Relatórios Gerenciais", mainHome)}>
                    Relatórios Gerenciais
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to={getURL("/relatoriocensoiniciado", "Relatórios Censo Iniciado", mainHome)}>
                    Relatório(s) Censo(s)
                    </NavLink>
                  </li>
                </ul>
              </li>
          </Secured>

          <Secured hasAnyRole={Permissions.AGENDAMENTO.MENU}>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to={getURL("/agendamento", "agendamento", mainHome)}
              >
                <i className="fas fa-calendar-alt" />
                Bloqueios e Desbloqueios
              </NavLink>
            </li>
          </Secured>
          <Secured hasAnyRole={Permissions.CALENDARIOSCENSO.MENU}>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to={getURL("/calendario", "calendario", mainHome)}
              >
                <i className="fas fa-calendar-week" />
                Calendário Censo
              </NavLink>
            </li>
          </Secured>
          <Secured hasAnyRole={Permissions.USUARIO.MENU}>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to={getURL("/usuario", "usuarios", mainHome)}
              >
                <i className="fas fa-user" />
                Administração do Usuário
              </NavLink>
            </li>
          </Secured>
          <Secured hasAnyRole={Permissions.AUDITORIA.MENU}>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to={getURL("/auditoria", "auditoria", mainHome)}
              >
                <i className="fas fa-list-alt" />
                Auditoria
              </NavLink>
            </li>
          </Secured>
        </ul>
        <ul className="nav flex-column bottom-control float-right d-none d-lg-block d-xl-block">
          <li className="nav-item">
            <a className="nav-link" onClick={handlePin}>
              <i className="fas fa-thumbtack" />
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Menu;
