import React from "react";
import { useParams, useHistory } from "react-router-dom";
import "./style.scss";
import Painel from "gp-painel";
import { applyMaskCpf } from "../../helper/mask";
import "gp-painel/dist/index.css";
import { Secured } from "../../component";
import { Permissions } from "../../Constantes/permissions.constants";
import {
  PlusOutlined,
  SaveOutlined,
  EditOutlined,
  SettingOutlined,
  UploadOutlined,
  DownloadOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import imagemAvatar from "../../imagens/avatar.png";

import {
  Col,
  Form,
  Row,
  Modal,
  message,
  Alert,
  Card,
  Image,
  Upload,
  Button,
  Avatar,
  Progress,
} from "antd";
import ModalHistorico from "./modalHistorico";
import {
  Contato,
  Endereco,
  ModalRepresentante,
  IdentificacaoPessoa,
} from "./Componentes/Componentes";
import StatusBeneficios from "../../component/StatusBeneficios";
import { Message } from "../../Constantes/message.constants";
import Parser from "html-react-parser";

import service from "./services";
import ModalFoto from "../../component/ModalFoto";

const statusAlert = {
  warn: { tipo: "warning", titulo: "Aviso" },
};

const Censo = () => {
  const [form] = Form.useForm();
  const { cpf, idCenso } = useParams();
  const { censo, setCenso } = React.useState({});
  const [valoresForm, setValoresForm] = React.useState({});
  const [ocultarEtapa2, setOcultarEtapa2] = React.useState(true);
  const [ocultarEtapa3, setOcultarEtapa3] = React.useState(true);
  const [mostrarModalHistorico, setMostrarModalHistorico] =
    React.useState(false);
  const [mostrarModalRepresentante, setMostrarModalRepresentante] =
    React.useState(false);
  const [existeBeneficioPensao, setExisteBeneficioPensao] =
    React.useState(false);
  const [exibeMensagemRepresentante, setExibeMensagemRepresentante] =
    React.useState(false);
  const [requerRepresentante, setRequerRepresentante] = React.useState(false);
  const history = useHistory();
  const controleEtapa2 = () => setOcultarEtapa2(false);
  const [fotoBase64, setFotoBase64] = React.useState();
  const [documentoBase64, setDocumentoBase64] = React.useState();
  const [certidaoBase64, setCertidaoBase64] = React.useState();
  const [modalFotoEstaAberto, setModalFotoEstaAberto] = React.useState(false);
  const [loadingCenso, setLoadingCenso] = React.useState(true);
  const { Meta } = Card;

  const handleValoresCPF = (dadosDoFormulario) => {
    setValoresForm((prev) => dadosDoFormulario);
    setRequerRepresentante(
      IdentificacaoPessoa.checandoDiferencaEntreDatas(
        dadosDoFormulario?.pessoa?.nascimento
      )
    );
    controleEtapa2(true);
  };

  React.useEffect(() => {
    form.setFieldsValue(valoresForm);
    setExisteBeneficioPensao(
      valoresForm?.beneficios?.filter((item) => item.pensao).length > 0
    );
  }, [valoresForm]);

  React.useEffect(() => {
    if (idCenso) {
      service.getCenso(idCenso).then((resp) => {
        setExisteBeneficioPensao(
          resp.data?.beneficios?.filter((item) => item.pensao).length > 0
        );

        if (resp.data.documentosBase64) {
          setDocumentoBase64(
            (prev) =>
              `data:application/pdf;base64, ${resp.data.documentosBase64}`
          );
        }

        if (resp.data.certidaoBase64) {
          setCertidaoBase64(
            (prev) => `data:application/pdf;base64, ${resp.data.certidaoBase64}`
          );
        }

        if (resp.data.fotoBase64) {
          setFotoBase64(
            (prev) => `data:image/jpeg;base64, ${resp.data.fotoBase64}`
          );
        }

        setExisteBeneficioPensao(
          resp.data?.beneficios?.filter((item) => item.pensao).length > 0
        );

        handleValoresCPF(resp.data);
        setLoadingCenso((prev) => false);
      });
    }
  }, [idCenso]);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        const blob = new Blob([reader.result], { type: file.type });
        const base64String = URL.createObjectURL(blob);
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const visualizarArquivo = async (file, tipoDocumento) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    if (tipoDocumento === "Documento") {
      setDocumentoBase64(file.url || file.preview);
    }

    if (tipoDocumento === "Certidao") {
      setCertidaoBase64(file.url || file.preview);
    }

    if (tipoDocumento === "Foto") {
      setFotoBase64(file.url || file.preview);
    }
  };

  const handleChangeDocumento = async (info, tipoDocumento) => {
    const tiposDeArquivosValidos = ["application/pdf"];

    let idArquivoAntigo = null;

    if (info.fileList.length > 0) {
      const file = info.fileList[0];

      if (
        file.originFileObj &&
        tiposDeArquivosValidos.includes(file.originFileObj.type)
      ) {
        const maxFileSize = 5 * 1024 * 1024; // 5 MB
        if (file.originFileObj.size > maxFileSize) {
          console.warn("O arquivo selecionado excede 5 MB.");
          return;
        }

        try {
          const base64 = await getBase64(file.originFileObj);

          if (tipoDocumento === "Documento") {
            setDocumentoBase64(base64);
            valoresForm.arquivoDocumentos = file.originFileObj;
            idArquivoAntigo = valoresForm.documentosToken;
          } else if (tipoDocumento === "Certidao") {
            setCertidaoBase64(base64);
            valoresForm.arquivoCertidao = file.originFileObj;
            idArquivoAntigo = valoresForm.certidaoToken;
          }

          await service
            .salvarArquivo(
              file.originFileObj,
              idCenso,
              tipoDocumento,
              idArquivoAntigo
            )
            .then((resp) => {});
        } catch (error) {
          console.warn("Erro ao converter para Base64:", error);
        }
      } else {
        console.warn("Arquivo inválido.");
      }
    }
  };

  const downloadArquivo = (base64String, fileName) => {
    // Verifica se a string Base64 já contém o prefixo correto
    if (!base64String.startsWith("data:")) {
      base64String = "data:application/octet-stream;base64," + base64String;
    }

    const link = document.createElement("a");
    link.href = base64String;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const noFinishModalRepresentante = (valores) => {
    valoresForm.representanteLegal = valores === null ? null : { ...valores };
    setExibeMensagemRepresentante(true);
    setMostrarModalRepresentante(false);
  };

  const cancelar = () => history.push("/", { forceRedirect: true });

  const imprimirComprovante = () => {
    service.visualizar(idCenso).catch(console.error);
  };

  const validacaoRepresentante = () => {
    return valoresForm?.representanteLegal == null && requerRepresentante;
  };

  const finalizarRecadastramento = async (data) => {
    service
      .formatarRequisicao(valoresForm, data)
      .then((objetoFormatado) => service.finalizar(objetoFormatado))
      .then((response) => {
        if (response) {
          message.success(Message.MSG005_RECADASTRO_SUCESSO, 4);
          setTimeout(cancelar, 4000);
        }
      });
  };

  const confirmarRecadastramento = (data) => {
    setExibeMensagemRepresentante(true);
    if (validacaoRepresentante()) {
      message.error("Representante é Obrigatório");
      return;
    }

    Modal.confirm({
      title: "Confirmação",
      content: `Deseja finalizar o recadastramento de ${data.pessoa.nome} para o ano ${valoresForm?.referencia}?`,
      okText: "Sim",
      onOk: () => finalizarRecadastramento(data),
    });
  };

  return (
    <>
      <Secured
        hasAnyRole={Permissions.RECADASTRAMENTO.TELA}
        isShowErro403={true}
      >
        <Form name="censo" form={form} onFinish={confirmarRecadastramento}>
          <Card
            title={loadingCenso ? "" : "Identificação do Segurado"}
            loading={loadingCenso}
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <IdentificacaoPessoa
              nome="pessoa"
              handleValoresCPF={handleValoresCPF}
              onClear={cancelar}
              onHandleRequerRepresentante={setRequerRepresentante}
              desabilitarCampos={!ocultarEtapa2 && ocultarEtapa3}
              desabilitarCPF={!ocultarEtapa2 || !ocultarEtapa3}
              ocultarCampos={ocultarEtapa2}
            />
          </Card>

          <Row gutter={10}>
            <Col xs={24} sm={24} md={16} lg={16} hidden={ocultarEtapa2}>
              <Card
                title="Documentos necessários"
                style={{ display: ocultarEtapa2 ? "none" : "block" }}
              >
                <Row gutter={[10, 10]} align="stretch">
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item
                      name="arquivoDocumentos"
                      rules={[
                        {
                          required: true,
                          message: "Por favor, insira o token Base64!",
                        },
                      ]}
                    >
                      <Card
                        style={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                        cover={
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {/* Condicionalmente exibe o ícone e texto com base no estado do arquivo */}
                            {documentoBase64 ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                              >
                                <CheckCircleOutlined
                                  style={{ color: "green", marginRight: 8 }}
                                />

                                <div>Documento Selecionado!</div>
                                <Progress percent={100} size="small" />
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                              >
                                <ExclamationCircleOutlined
                                  style={{ color: "orange", marginRight: 8 }}
                                />
                                <div>Selecione um Arquivo</div>
                                <Progress
                                  percent={2}
                                  size="small"
                                  status="exception"
                                />
                              </div>
                            )}
                          </div>
                        }
                        actions={[
                          <DownloadOutlined
                            key="download"
                            onClick={() =>
                              downloadArquivo(documentoBase64, "documentos.pdf")
                            }
                          />,

                          <Upload
                            maxCount={1}
                            onChange={(info) =>
                              handleChangeDocumento(info, "Documento")
                            }
                            onPreview={(arquivo) =>
                              visualizarArquivo(arquivo, "Documento")
                            }
                            showUploadList={false}
                            beforeUpload={() => false}
                          >
                            <UploadOutlined
                              key="documento"
                              title="Selecione um arquivo"
                            />
                          </Upload>,
                        ]}
                      ></Card>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item
                      name="arquivoCertidao"
                      rules={[
                        {
                          required: true,
                          message: "Por favor, insira o token Base64!",
                        },
                      ]}
                    >
                      <Card
                        style={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                        cover={
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {/* Condicionalmente exibe o ícone e texto com base no estado do arquivo */}
                            {certidaoBase64 ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                              >
                                <CheckCircleOutlined
                                  style={{ color: "green", marginRight: 8 }}
                                />

                                <div>Certidão Selecionada!</div>
                                <Progress percent={100} size="small" />
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                              >
                                <ExclamationCircleOutlined
                                  style={{ color: "orange", marginRight: 8 }}
                                />
                                <div>Selecione um Arquivo</div>
                                <Progress
                                  percent={2}
                                  size="small"
                                  status="exception"
                                />
                              </div>
                            )}
                          </div>
                        }
                        actions={[
                          <DownloadOutlined
                            key="download"
                            onClick={() =>
                              downloadArquivo(certidaoBase64, "certidao.pdf")
                            }
                          />,

                          <Upload
                            maxCount={1}
                            onChange={(info) =>
                              handleChangeDocumento(info, "Certidao")
                            }
                            onPreview={(arquivo) =>
                              visualizarArquivo(arquivo, "Certidao")
                            }
                            showUploadList={false}
                            beforeUpload={() => false}
                          >
                            <UploadOutlined
                              key="certidao"
                              title="Selecione um arquivo"
                            />
                          </Upload>,
                        ]}
                      ></Card>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} hidden={ocultarEtapa2}>
              <Card
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
                onClick={() => setModalFotoEstaAberto((prev) => true)}
              >
                <img
                  src={fotoBase64 || imagemAvatar}
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
              </Card>
            </Col>
          </Row>

          <Painel subTitulo="Benefício(s)" isExibe={!ocultarEtapa2}>
            {valoresForm?.beneficios?.map((item, index) => (
              <div key={index}>
                <StatusBeneficios item={item} />
              </div>
            ))}
            <Row>
              {valoresForm?.mensagens?.map((item, index) => (
                <Alert
                  key={index}
                  message={statusAlert[Object.keys(item)[0]].titulo}
                  description={Parser(item[Object.keys(item)[0]])}
                  type={statusAlert[Object.keys(item)[0]].tipo}
                  showIcon
                />
              ))}
            </Row>
            <Row
              type="flex"
              justify="end"
              hidden={ocultarEtapa2 || !ocultarEtapa3}
            >
              <Col>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => history.push("/")}
                  hidden={!valoresForm?.permiteAntecipar}
                >
                  <i className="fa fa-user-check" />
                  Antecipar Recadastramento {valoresForm?.referenciaAntecipar}
                </button>
              </Col>
              <Col>
                <button
                  type="button"
                  className="btn btn-outline-success mr-2"
                  onClick={() => setMostrarModalHistorico(true)}
                >
                  <i className="fa fa-search" />
                  Histórico
                </button>
              </Col>
              <Col>
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={() => setOcultarEtapa3(false)}
                  hidden={!valoresForm?.permiteRealizar}
                >
                  <i className="fa fa-user-check" />
                  Recadastramento {valoresForm?.referencia}
                </button>
              </Col>
            </Row>
          </Painel>

          <div hidden={ocultarEtapa2}>
            <Painel subTitulo="Endereço">
              <Endereco nome="endereco" form={form} status={controleEtapa2} />
              <Contato nome="contato" />
              <Row type="flex" justify="end">
                <Col>
                  <button
                    type="button"
                    className="btn btn-outline-dark mr-2"
                    onClick={cancelar}
                  >
                    <i className="fa fa-times" />
                    Cancelar
                  </button>
                  <button
                    type="button"
                    onClick={() => imprimirComprovante()}
                    className="btn btn-info"
                  >
                    <i className="fa fa-file-download" />
                    Gerar Comprovante
                  </button>
                </Col>
                <Col>
                  <button type="submit" className="btn btn-info">
                    <i className="fa fa-user-check" />
                    Finalizar Recadastramento
                  </button>
                </Col>
              </Row>
            </Painel>
          </div>
        </Form>
        {mostrarModalHistorico && (
          <ModalHistorico
            show
            close={() => setMostrarModalHistorico(false)}
            onRetificar={(id) => {
              setMostrarModalHistorico(false);
              setOcultarEtapa3(false);
              valoresForm["mensagens"] = [];
            }}
            cpf={form.getFieldValue(["pessoa", "cpf"])}
            nome={form.getFieldValue(["pessoa", "nome"])}
          />
        )}

        {modalFotoEstaAberto && (
          <ModalFoto
            open={modalFotoEstaAberto}
            close={() => setModalFotoEstaAberto(false)}
            setFoto={setFotoBase64}
            eRetificacao={idCenso ? true : false}
            idCenso={idCenso}
            idArquivoAntigo={valoresForm.fotoToken}
          />
        )}
      </Secured>
    </>
  );
};

export default Censo;
