import React, { useMemo, useState, useEffect } from "react";
import "./indexStyle.css";
import { Table, Card, Form, Select, DatePicker, Tag, Button } from "antd";
import { PageContainer } from "@ant-design/pro-layout";
import { QueryFilter } from "@ant-design/pro-form";
import "react-dual-listbox/lib/react-dual-listbox.css";
import services from "../services";
import { jsPDF } from "jspdf";
import domtoimage from "dom-to-image";
import "react-dual-listbox/lib/react-dual-listbox.css";
import { Permissions } from "../../Constantes/permissions.constants";
import { formatDate, formatCpf } from "../../helper/util";
import { Secured } from "../../component";
import InputCPF from "../../component/InputCpf";

import { Spin } from "antd";

const RelatorioGerencial = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [filterValues, setFilterValues] = useState();
  const [recordLoadingButton, setRecordLoadingButton] = useState(false);
  
  const [cpf, setCPF] = useState();

  const months = {
    1: "Janeiro",
    2: "Fevereiro",
    3: "Março",
    4: "Abril",
    5: "Maio",
    6: "Junho",
    7: "Julho",
    8: "Agosto",
    9: "Setembro",
    10: "Outubro",
    11: "Novembro",
    12: "Dezembro",
  };

  const benefit = {
    1: "APOSENTADORIA",
    2: "PENSAO",
  };
  const status = {
    0: "Ativo",
    1: "Bloqueado",
    2: "Suspenso",
    3: "Cancelado",
    4: "Extinto",
  };

  const statusBackEnd = {
    0: 1, // Ativo -> 1
    1: 2, // Bloqueado -> 2
    2: 3, // Suspenso -> 3
    3: 4, // Cancelado -> 4
    4: 5, // Extinto -> 5
  };

  const residencia = {
    1: "Dentro da UF",
    2: "Fora da UF",
  };

  const getReports = (parametros) => {
    setLoading(true);

    services
      .getReports(parametros)
      .then((resp) => {
        setData(resp?.data);
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    if (filterValues) getReports(filterValues);
  }, [filterValues]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const pagination = {
      page: 0,
      size: 10,
    };

    getReports(pagination);
  }, []);

  const onChangeTable = (pagination, filters, sorter) => {
    const updatedFilters = {
      ...filterValues,
      page: pagination.current - 1,
      size: pagination.pageSize,
      sort: sorter.order
        ? `${sorter.columnKey},${sorter.order.replace("end", "")}`
        : "",
    };

    setFilterValues(updatedFilters); // Atualiza os filtros no estado
  };

  useEffect(() => {
    if (filterValues) getReports(filterValues);
  }, [filterValues]);

  const columns = useMemo(
    () => [
      {
        title: "Status do Recadastramento",
        dataIndex: "status",
        key: "status",
        width: "100px",
        sorter: true,
        render: (text, index) => (
          <Tag
            color={
              text == "Ativo" ? "green" : text == "Extinto" ? "gold" : "red"
            }
          >
            {text}
          </Tag>
        ),
      },
      {
        title: "CPF",
        dataIndex: "cpf",
        key: "cpf",
        render: (text) => formatCpf(text),
        width: "200px",
      },
      {
        title: "Nome",
        dataIndex: "nome",
        key: "pessoa",
        width: "300px",
      },
      {
        title: "Data de Nascimento",
        dataIndex: "nascimento",
        key: "nascimento", //corrigir esta data
        width: "100px",
      },
      {
        title: "Tipo de Benefício",
        dataIndex: "tipo",
        sorter: true,
        key: "aposentadoria",
        render: (text) => text,
        width: "200px",
      },
    ],
    []
  );

  const onSearch = async (values) => {
    try {
      if (values.status !== undefined) {
        values.status = statusBackEnd[values.status];
      }

      console.log("Dados do formulário:", values);
      const updatedFilters = { ...filterValues, ...values, page: 0 }; // Reseta para a primeira página
      setFilterValues(updatedFilters);

      const response = await getReports(updatedFilters);
      console.log("Resposta da API:", response.data);
      setData(response.data);
    } catch (error) {
      console.error("Erro ao buscar relatórios:", error);
    }
  };

  const imprimirConteudo = () => {
    document.getElementsByClassName("table-antd")[0].id = "id";
    let html = document.getElementById("id");
    var doc = new jsPDF("l", "pt", [800, 470]);
    if (html) {
      domtoimage.toPng(html).then((imgData) => {
        const imgProps = doc.getImageProperties(imgData);
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        doc.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        window.open(doc.output("bloburl"));
        setRecordLoadingButton(false);
      });
    }
  };

  const gerarRelatorio = (event) => {
    setRecordLoadingButton(true);
    imprimirConteudo("Relatório Gerencial", "imprimir");
  };

  return (
    <>
      <Secured hasAnyRole={Permissions.RELATORIOS.TELA} isShowErro403={true}>
        <PageContainer>
          <h1>Gerencial Censo</h1>
          <Card
            title="Parâmetros do Gerencial"
            style={{
              fontSize: "20px",
              marginBottom: "10px",
              position: "relative",
            }}
          >
            <QueryFilter
              onFinish={onSearch}
              onReset={() => {
                const initialFilters = { page: 0, size: 10 };
                setFilterValues(initialFilters);
                getReports(initialFilters);
              }}
              layout="vertical"
              defaultCollapsed
              className="filtro"
              disabled={loading}
            >

             <Form.Item
                             label="Cpf:"
                             name="cpf"
                             rules={[{ validator: InputCPF.Validator }]}
                           >
                             <InputCPF placeholder="Digite o N° do Cpf" showSearch={false} />
                           </Form.Item>

              <Form.Item label="Tipo de Benefício:" name="tipo">
                <Select
                  placeholder="Selecione..."
                  allowClear={true}
                  options={Object.keys(benefit).map((key) => ({
                    label: benefit[key], // Exibe o nome do benefício
                    value: benefit[key], // Envia o nome (texto) para o back-end
                  }))}
                />
              </Form.Item>
              <Form.Item label="Status do recadastramento:" name="status">
                <Select
                  placeholder="Selecione..."
                  allowClear={true}
                  options={Object.keys(status).map((value, index) => ({
                    label: status[value],
                    value: index,
                  }))}
                />
              </Form.Item>

              <Form.Item label="Mês do Nascimento:" name="mesNascimento">
                <Select
                  placeholder="Selecione..."
                  allowClear={true}
                  options={Object.keys(months).map((value, index) => ({
                    label: months[value],
                    value: months[value],
                  }))}
                />
              </Form.Item>

              <Form.Item label="Residência:" name="uf">
                <Select
                  placeholder="Selecione..."
                  allowClear={true}
                  options={Object.keys(residencia).map((value, index) => ({
                    label: residencia[value],
                    value: index === 1 ? "" : "GO",
                  }))}
                />
              </Form.Item>

              <Form.Item
                label="Período Inicial:"
                shouldUpdate={(prevValues, curValues) => {
                  return (
                    prevValues.valueIni !== curValues.valueIni ||
                    prevValues.valueEnd !== curValues.valueEnd
                  );
                }}
              >
                {({ getFieldValue }) => {
                  const startDatePreenchido0 = getFieldValue("valueIni");

                  const endDatePreenchido0 = getFieldValue("valueEnd");

                  return (
                    <Form.Item
                      name="valueIni"
                      dependencies={["valueEnd"]}
                      rules={[
                        {
                          required: endDatePreenchido0 && true,
                          message: "Por Favor, insira data",
                        },
                        {
                          validator: async () => {
                            if (startDatePreenchido0 > endDatePreenchido0) {
                              return Promise.reject(
                                new Error("Data inicial e maior que data final")
                              );
                            }
                          },
                        },
                      ]}
                    >
                      <DatePicker
                        name="dataFinalVigencia"
                        placeholder="Digite a data"
                        allowClear
                        format="DD/MM/YYYY"
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>

              <Form.Item
                label="Periodo Final:"
                shouldUpdate={(prevValues, curValues) => {
                  return (
                    prevValues.valueIni !== curValues.valueIni ||
                    prevValues.valueEnd !== curValues.valueEnd
                  );
                }}
              >
                {({ getFieldValue }) => {
                  const startDatePreenchido0 = getFieldValue("valueIni");
                  const endDatePreenchido0 = getFieldValue("valueEnd");

                  return (
                    <Form.Item
                      name="valueEnd"
                      dependencies={["valueIni"]}
                      rules={[
                        {
                          required: startDatePreenchido0 && true,
                          message: "Por Favor, insira data",
                        },
                        {
                          validator: async () => {
                            if (endDatePreenchido0) {
                              if (endDatePreenchido0 < startDatePreenchido0) {
                                return Promise.reject(
                                  new Error(
                                    "Data final e menor que data inicial."
                                  )
                                );
                              }
                            }
                          },
                        },
                      ]}
                    >
                      <DatePicker
                        disabledDate={(d) => d.isBefore(startDatePreenchido0)}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </QueryFilter>
            <Table
              className="table-antd"
              onChange={onChangeTable}
              columns={columns}
              dataSource={data?.content}
              pagination={{
                current: data?.number ? data?.number + 1 : 1,
                pageSize: data?.size,
                total: data?.totalElements,
                showSizeChanger: true,
                position: ["bottomCenter"],
              }}
              rowKey="id"
              loading={loading}
            />
          </Card>

          <Card>
            {/*<div className="row col-md-12 button-right p-0 m-0">
            <Button
                            loading={recordLoadingButton}
                            type="primary"
                            onClick={() => gerarRelatorio()}
            >
                            Gerar Relatório
            </Button>
            </div>*/}

          <div>
            <div className="d-flex justify-content-end align-items-center button-container">
              <Button
                type="primary"
                onClick={() => window.open('https://reports.goiasprev.go.gov.br/Reports/browse/GoiasPrev/Cadastro', '_blank')}
                className="me-2" 
                style={{ marginRight: '10px' }} // Espaçamento entre os botões
              >
                Servidor de Relatórios / Gráficos do BI
              </Button>
            </div>
              <div className="titulo-gerencial">
                <h2>Gerencial Censo Anual</h2>
              </div>
              <div>
                <br /> <br />
              </div>
              <div className="iframe-container">
                <iframe
                  src="https://reports.goiasprev.go.gov.br/Reports/powerbi/GoiasPrev/Cadastro/CensoAnualTotal?rs:Embed=true"
                  frameBorder="0"
                  title="BI Censo"
                  allowFullScreen
                />
              </div>

              <div className="titulo-gerencial">
                <h2>Gerencial Situações Previdenciárias</h2>
              </div>
              <div>
                <br /> <br />
              </div>
              <div className="iframe-container">
                <iframe
                  src="https://reports.goiasprev.go.gov.br/Reports/powerbi/GoiasPrev/Cadastro/SituacoesPrevidenciarias?rs:Embed=true"
                  frameBorder="0"
                  title="Situações Previdenciárias"
                  allowFullScreen
                />
              </div>
            </div>
          </Card>
        </PageContainer>
      </Secured>
    </>
  );
};

export default RelatorioGerencial;
