import { Permissions } from "./Constantes/permissions.constants";
import Agendamento from "./page/Agendamento";
import Recadastramento from "./page/Recadastramento/index";
import Censo from "./page/Censo/index";
import RelatorioGerencial from "./page/Relatorios/index";
import AdministracaoAuditoria from "./page/Auditoria";
import AdministracaoUsuario from "./page/AdministracaoUsuario";
import CalendariosCenso from "./page/Calendario/CalendariosCenso";
import CalendarioNovo from "./page/Calendario/NovoCalendarioCenso";

import RelatorioCensoIniciado from "./page/Relatorios/censoIniciado";

export const configRoutes = [
  {
    exact: true,
    permissao: Permissions.RECADASTRAMENTO.MENU,
    component: Recadastramento,
    tipo: "recadastramento",
    path: "/",
  },

  {
    exact: true,
    permissao: Permissions.CALENDARIOSCENSO.MENU,
    component: Censo,
    tipo: "recadastramento",
    path: "/recadastramento/censo/:cpf/:idCenso",
  },

  {
    exact: true,
    permissao: Permissions.RELATORIOS.MENU,
    component: RelatorioGerencial,
    tipo: "relatoriogerencial",
    path: "/relatoriogerencial",
  },
  {
    exact: true,
    permissao: Permissions.RELATORIOS.MENU,
    component: RelatorioCensoIniciado,
    tipo: "relatoriocensoiniciado",
    path: "/relatoriocensoiniciado",
  },
  {
    exact: true,
    permissao: Permissions.AGENDAMENTO.MENU,
    component: Agendamento,
    tipo: "agendamento",
    path: "/agendamento",
  },
  {
    exact: true,
    permissao: Permissions.USUARIO.MENU,
    component: AdministracaoUsuario,
    tipo: "usuario",
    path: "/usuario",
  },
  {
    exact: true,
    permissao: Permissions.AUDITORIA.MENU,
    component: AdministracaoAuditoria,
    tipo: "auditoria",
    path: "/auditoria",
  },
  {
    exact: true,
    permissao: Permissions.CALENDARIOSCENSO.MENU,
    component: CalendariosCenso,
    tipo: "calendario",
    path: "/calendario",
  },
  {
    exact: true,
    permissao: Permissions.CALENDARIOSCENSO.MENU,
    component: CalendarioNovo,
    tipo: "calendariosalvar",
    path: "/calendario/salvar",
  },

  {
    exact: true,
    permissao: Permissions.CALENDARIOSCENSO.MENU,
    component: CalendarioNovo,
    tipo: "calendariosalvar",
    path: "/calendario/salvar/:id",
  },
];
