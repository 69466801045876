import React, { useRef, useState } from "react";
import { Modal, Button, Row, Col } from "antd";
import service from "../../page/Censo/services";
import {
  PlusOutlined,
  CameraOutlined,
  SaveOutlined,
  RedoOutlined,
} from "@ant-design/icons";

const ModalFoto = ({
  open,
  close,
  setFoto,
  eRetificacao,
  idCenso,
  idArquivoAntigo,
}) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [fotoTemp, setFotoTemp] = useState(null);
  const [cameraAtiva, setCameraAtiva] = useState(false);

  const base64ToBlob = (base64Data, contentType) => {
    const base64Clean = base64Data.split(",")[1];

    const byteCharacters = atob(base64Clean);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      byteArrays.push(new Uint8Array(byteNumbers));
    }

    return new Blob(byteArrays, { type: contentType });
  };

  const abrirCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
      setCameraAtiva(true);
      setFotoTemp(null);
    } catch (error) {
      console.error("Erro ao acessar a câmera:", error);
      alert("Não foi possível acessar a câmera.");
    }
  };

  const tirarFoto = () => {
    if (!videoRef.current || !canvasRef.current) return;

    const canvas = canvasRef.current;
    const video = videoRef.current;
    const contexto = canvas.getContext("2d");

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    contexto.drawImage(video, 0, 0, canvas.width, canvas.height);

    const fotoData = canvas.toDataURL("image/jpeg");
    setFotoTemp(fotoData);
    setCameraAtiva(false);

    if (video.srcObject) {
      video.srcObject.getTracks().forEach((track) => track.stop());
    }
  };

  const tentarNovamente = () => {
    setFotoTemp((prev) => null);
    abrirCamera();
  };

  const salvarFoto = async () => {
    if (eRetificacao) {
      const imagemBlob = base64ToBlob(fotoTemp, "image/jpeg");

      await service.salvarArquivo(imagemBlob, idCenso, "Foto", idArquivoAntigo);
    }
    setFoto((prev) => fotoTemp); // Atualiza o estado no componente pai
    close(); // Fecha o modal
  };

  return (
    <Modal
      title="Foto para Recadastramento"
      open={open}
      onCancel={close}
      centered
      width={600}
      footer={[
        <Button
          key="abrir"
          type="primary"
          onClick={abrirCamera}
          icon={<PlusOutlined />}
          disabled={cameraAtiva || fotoTemp}
        >
          Abrir Câmera
        </Button>,
        <Button
          key="foto"
          onClick={tirarFoto}
          icon={<CameraOutlined />}
          disabled={!cameraAtiva}
        >
          Tirar Foto
        </Button>,
        <Button
          key="tentar"
          onClick={tentarNovamente}
          icon={<RedoOutlined />}
          disabled={!fotoTemp}
        >
          Tentar Novamente
        </Button>,
        <Button
          key="salvar"
          type="primary"
          onClick={salvarFoto}
          icon={<SaveOutlined />}
          disabled={!fotoTemp}
        >
          Salvar
        </Button>,
      ]}
    >
      {!fotoTemp ? (
        <video ref={videoRef} autoPlay width={"100%"} />
      ) : (
        <img src={fotoTemp} alt="Foto Capturada" width={"100%"} />
      )}
      <canvas ref={canvasRef} style={{ display: "none" }} />
    </Modal>
  );
};

export default ModalFoto;
