import React, { useEffect } from "react";
import { Secured } from "../../../component";
import { Permissions } from "../../../Constantes/permissions.constants";
import { MailFilled, FileOutlined } from "@ant-design/icons";
//import { useHistory } from "react-router-dom";
import "../style.less";
import { Form, Col, Card, Row, Button, Input, message,DatePicker,Modal } from "antd";
import services from "../services";
import { formatDate,formatarData, formatCpf,formatDateLessTime } from "../../../helper/util";

import { imprimir,imprimirDoc } from "../../../helper/util";
import { format } from 'date-fns';



//let history = useHistory();

const Desbloqueio = () => {
  const [form] = Form.useForm();

  const initialValuesSchema = {
    email: "",
  };

  useEffect(() => {
    form.setFieldsValue(initialValuesSchema);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, initialValuesSchema]);

  const sendEmail = async (data) => {
    // Valida todos os campos de uma vez
    await form.validateFields([
      "dataBloqueio",
      "dataSuspensao",
      "dataInicioRecolhimento",
      "dataFimRecolhimento"
    ]);
  
    try {
     
      Modal.confirm({
        title: 'Você tem certeza?',
        content: 'Ao confirmar, o e-mail será enviado e os usuários na listagem serão desbloqueados no sistema. Deseja continuar?',
        okText: 'Sim',
        cancelText: 'Cancelar',
        onOk: async () => {

          // Formata as datas
          data.dataBloqueio = formatDateLessTime(form.getFieldValue("dataBloqueio"));
          data.dataSuspensao = formatDateLessTime(form.getFieldValue("dataSuspensao"));
          data.dataInicioRecolhimento = formatDateLessTime(form.getFieldValue("dataInicioRecolhimento"));
          data.dataFimRecolhimento = formatDateLessTime(form.getFieldValue("dataFimRecolhimento"));

          // Prepara os parâmetros para envio
          const params = { 
            dataBloqueio: data.dataBloqueio, 
            dataSuspensao: data.dataSuspensao,
            dataInicioRecolhimento: data.dataInicioRecolhimento,
            dataFimRecolhimento: data.dataFimRecolhimento
          };

          // Envia o e-mail
          const response = await services.sendEmail(params);
            
          if (response && response.status === 200) {
            message.success("E-mail enviado com sucesso!");
            form.resetFields(); // Limpa o formulário após o envio
          }
        }});
  
     
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error?.message || "Erro desconhecido. Tente novamente.";
      message.error(errorMessage);
    }
  };
  
  
  const imprimirDocx = async (data) =>{
     
    await form.validateFields(["dataBloqueio"]);
    await form.validateFields(["dataSuspensao"]); 
    await form.validateFields(["dataInicioRecolhimento"]); 
    await form.validateFields(["dataFimRecolhimento"]); 

    data.dataBloqueio = formatDateLessTime(form.getFieldValue("dataBloqueio"));
    data.dataSuspensao = formatDateLessTime(form.getFieldValue("dataSuspensao"));
    data.dataInicioRecolhimento = formatDateLessTime(form.getFieldValue("dataInicioRecolhimento"));
    data.dataFimRecolhimento = formatDateLessTime(form.getFieldValue("dataFimRecolhimento"));


    const params = { dataBloqueio:data.dataBloqueio, 
      dataSuspensao:data.dataSuspensao,
      dataInicioRecolhimento:data.dataInicioRecolhimento ,
      dataFimRecolhimento:data.dataFimRecolhimento
    };
    
    let filename = "participantesBloqueadosQueFizeramCenso.docx";
    services.getReportDocx(params).then((response) => {
      imprimirDoc(response,filename);
    })
    .catch((error) => {});
  }
  
  const imprimirPdf =async (data) =>{
    
    await form.validateFields(["dataBloqueio"]);
    await form.validateFields(["dataSuspensao"]); 
    await form.validateFields(["dataInicioRecolhimento"]); 
    await form.validateFields(["dataFimRecolhimento"]); 

    data.dataBloqueio = formatDateLessTime(form.getFieldValue("dataBloqueio"));
    data.dataSuspensao = formatDateLessTime(form.getFieldValue("dataSuspensao"));
    data.dataInicioRecolhimento = formatDateLessTime(form.getFieldValue("dataInicioRecolhimento"));
    data.dataFimRecolhimento = formatDateLessTime(form.getFieldValue("dataFimRecolhimento"));


    const params = { dataBloqueio:data.dataBloqueio, 
      dataSuspensao:data.dataSuspensao,
      dataInicioRecolhimento:data.dataInicioRecolhimento ,
      dataFimRecolhimento:data.dataFimRecolhimento
    };

    let filename = "participantesBloqueadosQueFizeramCenso.pdf";
    services.getReport(params).then((response) => {
      imprimirDoc(response,filename);
    })
    .catch((error) => {});

      ;
  }

  return (
    <>
      <Secured
        hasAnyRole={Permissions.RECADASTRAMENTO.TELA}
        isShowErro403={true}
      >
        <Form layout="vertical" form={form}>
          <Card title="Relatório de Desbloqueio">
            <Row>

            <Col sm={18} md={10} lg={6}>
              <Form.Item
                required
                label="Último Bloqueio Informado:"
                shouldUpdate={(prevValues, curValues) => {
                  return (
                      prevValues.dataBloqueio !== curValues.dataBloqueio ||
                      prevValues.valueEnd !== curValues.valueEnd
                  );
                  }}
            >
                {({ getFieldValue }) => (
                    <Form.Item
                        name="dataBloqueio"
                        rules={[
                            {
                                required: true,
                                message: "O campo Último Bloqueio Informado é obrigatório",
                            },
                        ]}
                    >
                        <DatePicker
                            placeholder="Digite a data"
                            allowClear
                            format="DD/MM/YYYY"
                        />
                    </Form.Item>
                )}
              </Form.Item>

            </Col>
              <Col sm={18} md={10} lg={6}>
                <Form.Item
                    required
                    label="Última Suspensão Informada:"
                    shouldUpdate={(prevValues, curValues) => 
                        prevValues.dataSuspensao !== curValues.dataSuspensao ||
                        prevValues.valueEnd !== curValues.valueEnd
                    }
                >
                    {({ getFieldValue }) => {
                        const dataSuspensao = getFieldValue("dataSuspensao");

                        return (
                            <Form.Item
                                name="dataSuspensao"
                                rules={[
                                    {
                                        required: true,
                                        message: "O campo Última Suspensão Informada é obrigatório",
                                    },
                                ]}
                            >
                                <DatePicker
                                    placeholder="Digite a data"
                                    allowClear
                                    format="DD/MM/YYYY"
                                />
                            </Form.Item>
                        );
                    }}
                </Form.Item>  
              </Col>


              <Col sm={24} md={10} lg={6}>
                <Form.Item
                    required
                    label="Data Inicio Recolhimento:"
                    shouldUpdate={(prevValues, curValues) => {
                    return (
                        prevValues.dataInicioRecolhimento !== curValues.dataInicioRecolhimento ||
                        prevValues.valueEnd !== curValues.valueEnd
                    );
                    }}
                >
                    {({ getFieldValue }) => {
                    const dataInicioRecolhimento = getFieldValue("dataInicioRecolhimento");

                    const dataFimRecolhimento = getFieldValue("dataFimRecolhimento");

                    return (
                        <Form.Item
                        name="dataInicioRecolhimento"
                        dependencies={["dataFimRecolhimento"]}
                        rules={[
                            {
                            required: dataFimRecolhimento && true,
                            message: "Data Inicio Recolhimento é obrigatória",
                            },
                            {
                            validator: async () => {
                                if (dataInicioRecolhimento == null || dataInicioRecolhimento == "") {
                                return Promise.reject(
                                    new Error("Período inicial é obrigatório")
                                );
                                }
                            },
                            },
                        ]}
                        >
                        <DatePicker
                            name="dataInicioRecolhimento"
                            placeholder="Digite a data"
                            allowClear
                            format="DD/MM/YYYY"
                        />
                        </Form.Item>
                    );
                    }}
                </Form.Item>


  
              </Col>
              <Col sm={24} md={10} lg={6}>
                <Form.Item
                      label="Data Fim Recolhimento:"
                      required
                      shouldUpdate={(prevValues, curValues) => {
                      return (
                          prevValues.dataInicioRecolhimento !== curValues.dataInicioRecolhimento ||
                          prevValues.dataFimRecolhimento !== curValues.dataFimRecolhimento
                      );
                      }}
                  >
                      {({ getFieldValue,setFieldsValue}) => {
                      const dataInicioRecolhimento = getFieldValue("dataInicioRecolhimento");
                      const dataFimRecolhimento = getFieldValue("dataFimRecolhimento");

                      return (
                          <Form.Item
                          name="dataFimRecolhimento"
                          dependencies={["dataInicioRecolhimento"]}
                          rules={[
                            {
                              required: true,
                              message: "Data Fim Recolhimento é obrigatória",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                const dataInicio = getFieldValue("dataInicioRecolhimento");
                                if (value && dataInicio && value.isBefore(dataInicio, "day")) {
                                  setFieldsValue({ dataFimRecolhimento: null });
                                  return Promise.reject(new Error("Data final é menor que a data inicial."));
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <DatePicker
                              disabledDate={(d) => d.isBefore(dataInicioRecolhimento)}
                          />
                          </Form.Item>
                      );
                      }}
                  </Form.Item>
              
              
              </Col>

              <Col sm={19} md={15} lg={4} className="align-col-button2">
                <Button
                  type="primary"
                  icon={<FileOutlined />}
                  onClick={imprimirPdf}
                  style={{ marginTop: 25 }}
                >
                  Download Relatório PDF
                </Button>

              </Col>

              <Col sm={19} md ={15} lg={4} className="align-col-button2">      
                  
                  <Button
                      type="primary"
                      icon={<FileOutlined />}
                      onClick={imprimirDocx}
                      style={{ marginTop: 25,backgroundColor: "#52c41a", borderColor: "#52c41a"}}
                    >
                      Download Relatório Docx
                    </Button>
              </Col>


              <Col sm={19} md={15} lg={4} className="align-col-button2">
                <Button
                  type="primary"
                  icon={<MailFilled />}
                  onClick={sendEmail}
                  style={{ marginTop: 25 }}
                >
                  Enviar Relatório
                </Button>
              </Col>
            </Row>
          </Card>
        </Form>
      </Secured>
    </>
  );
};

export default Desbloqueio;
