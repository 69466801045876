import React, { useContext } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Alert from "./component/Alert";
import Menu from "./component/Menu";
import ListeningRoute from "./helper/ListeningRoute";
import Erro404 from "./page/Erro404";
import Erro403 from "./page/Erro403";
import useSecurityContext from "./component/Security/useSecurityContext";
import { useState } from "react";
import { configRoutes } from "./configRoutes";
import { useEffect } from "react";
import * as MainHomeActions from "./state/Home/actions";
import MainHomeContext from "./state/Home/Context";
import RouteChildren from "./RouteChildren";

export default function Routes() {
  const { hasPermission } = useSecurityContext();
  const { mainHomeDispatch } = useContext(MainHomeContext);

  const [componentPrincipal, setComponentPrincipal] = useState({
    component: null,
  });

  useEffect(() => {
    let arrayComponentes = [];
    configRoutes.map((item) => {
      const permissao = item.permissaoAll ? item.permissaoAll : item.permissao;
      if (hasPermissao(permissao, item.permissaoAll)) {
        arrayComponentes.push(item);
      }
      return item;
    });
    if (arrayComponentes[0]) {
      setComponentPrincipal(arrayComponentes[0]);
      mainHomeDispatch(MainHomeActions.add(arrayComponentes[0].tipo));
    }
  }, []); // eslint-disable-line

  const hasPermissao = (permissions, isAll) =>
    isAll
      ? hasAllPermissionRoute(permissions)
      : hasPermissionRoute(permissions);
  const hasPermissionRoute = (hasAnyRole) =>
    hasPermission({ hasAnyRole: hasAnyRole });
  const hasAllPermissionRoute = (hasAllRole) =>
    hasPermission({ hasAllRole: hasAllRole });

  const getRoute = (item, index) => {
    if (item.children && item.children.length > 0) {
      return (
        <Route
          key={index}
          path={item.path}
          exact={item.exact}
          render={(props) => (
            <RouteChildren {...props} routeChildren={item.children} />
          )}
        />
      );
    } else {
      return (
        <Route
          key={index}
          path={item.path}
          exact={item.exact}
          component={item.component}
        ></Route>
      );
    }
  };

  return (
    <BrowserRouter>
      <Menu></Menu>
      <ListeningRoute></ListeningRoute>
      <main className="col content" role="main">
        <Alert />
        <Switch>
          <Route path="/" exact component={componentPrincipal.component} />
          {configRoutes.map(
            (item, index) =>
              item.tipo !== componentPrincipal && getRoute(item, index)
          )}
          <Route path="/acesso-negado" component={Erro403} />
          <Route component={Erro404} />
        </Switch>
      </main>
    </BrowserRouter>
  );
}
