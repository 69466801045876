import axios from "axios";
import config from "../../config";

const BASE_API = config.BASE_API;

const obterCalendario = (id) =>
  axios.get(`${BASE_API}bloqueios/calendario/${id}`);

const obterListaCalendario = () =>
  axios.get(`${BASE_API}bloqueios/calendarios`);

const salvarCalendario = (calendario) =>
  axios.post(`${BASE_API}bloqueios/salvar`, calendario);

const alterarCalendario = (calendario) =>
  axios.put(`${BASE_API}bloqueios/editar`, calendario);

const excluirCalendario = (id) => axios.delete(`${BASE_API}bloqueios/${id}`);

export default {
  obterCalendario,
  obterListaCalendario,
  salvarCalendario,
  alterarCalendario,
  excluirCalendario,
};
