import axios from "axios";
import config from "../../config";
import { imprimir } from "../../helper/util";

const BASE_API = config.BASE_API;
const ENDERECO_API = config.ENDERECO_API;

const obterCPF = (cpf, antecipar = false) => {
  return axios.get(
    `${BASE_API}recadastramento/segurado/${cpf}?antecipar=${antecipar}`
  );
};

const getCenso = (idCenso) => {
  return axios.get(BASE_API + `recadastramento/censo/${idCenso}`);
};

const obterRepresentante = (cpf) => {
  return axios.get(BASE_API + "recadastramento/representante/" + cpf);
};

const retificar = (id) => {
  return axios.get(BASE_API + "recadastramento/censo/" + id);
};

const getEndereco = (cep, if404Endereco) => {
  return axios
    .get(ENDERECO_API + "enderecos/" + cep)
    .then((response) => response.data)
    .catch(if404Endereco);
};

const finalizar = (params) => {
  return axios.post(BASE_API + "recadastramento", params, {
    responseType: "json",
  });
};
const visualizar = (idCenso) => {
  return axios
    .get(BASE_API + "recadastramento/comprovante/" + idCenso, {
      responseType: "blob",
    })
    .then((resp) => (resp ? resp.data : resp))
    .then((data) => {
      imprimir(data);
    });
};

const obterUF = (sigla) => {
  return axios.get(ENDERECO_API + "ufs/" + sigla);
};

const obterCidades = (uf) => {
  return axios.get(ENDERECO_API + "municipios?uf=" + uf);
};

const obterPais = () => {
  return axios.get(BASE_API + "recadastramento/paises");
};

const obterHistorico = (cpf) => {
  return axios.get(BASE_API + "recadastramento/historico/" + cpf);
};

const obterBairros = (cidade) => {
  return axios.get(ENDERECO_API + "bairros?municipio_id=" + cidade);
};

const getOpcoes = () => axios.get(BASE_API + "opcoes");

const getParametros = () => axios.get(`${BASE_API}recadastramento/parametros`);

const getArquivo = (token) =>
  axios.get(`${BASE_API}recadastramento/arquivo/${token}`);

const checkandoArquivoUpload = (fileList) => {
  return (
    Array.isArray(fileList) && fileList?.length > 0 && fileList[0].uid !== "-1"
  );
};

const mapCertidao = (valores, values, existeBeneficioPensao) => {
  return existeBeneficioPensao
    ? {
        certidao: {
          ...valores.certidao,
          ...values.certidao,
        },
      }
    : {};
};

const mapPessoa = (valores, attr) => {
  if (attr.endereco.bairro.nome) {
    attr.endereco.bairro = attr.endereco.bairro.nome;
  }

  return {
    pessoa: {
      ...valores.pessoa,
      ...attr.pessoa,
      ...attr.endereco,
      ...attr.contato,
    },
  };
};

const mapEndereco = (attr) => {
  return {
    endereco: {
      bairro: { nome: attr.bairro },
      cep: attr.cep,
      cidade: attr.cidade,
      complemento: attr.complemento,
      logradouro: attr.logradouro,
      pais: attr.pais,
      uf: attr.uf,
    },
  };
};

const mapRepresentanteLegal = (representanteLegal, tokenDocumento) => {
  return representanteLegal
    ? {
        representanteLegal: {
          ...representanteLegal,
          tokenDocumento,
        },
      }
    : {};
};

const formatarRequisicao = (
  valores,
  { pessoa, endereco, contato, ...values }
) => {
  const enderecoFormatado = {
    cep: endereco.cep,
    complemento: endereco.complemento,
    logradouro: endereco.logradouro,
    cidade: endereco.cidade,
    uf: endereco.cidade,
    bairro: endereco.bairro.nome,
    pais: endereco.pais,
  };

  const enderecoPessoa = {
    cep: endereco.cep,
    complemento: endereco.complemento,
    logradouro: endereco.logradouro,
    cidade: endereco.cidade,
    uf: endereco.cidade,
    pais: endereco.pais,
    bairro: {
      versaoDoObjeto: 0,
      municipio: endereco.cidade,
      distrito: endereco.uf,
      nome: endereco.bairro.nome,
      DNELogBairro: endereco.logradouro,
    },
  };

  var data = new FormData();

  data.append(
    "censo",
    new Blob(
      [
        JSON.stringify({
          ...valores,
          ...mapPessoa(valores, { pessoa, endereco, contato }),
          endereco,
          contato,
        }),
      ],
      {
        type: "application/json",
      }
    )
  );
  return Promise.resolve(data);
};

const getComprovante = (idCenso) =>
  axios.get(`${BASE_API}comprovante/${idCenso}`);

const uploadArquivo = (file, idCenso, tipoArquivoCenso, idArquivoAntigoCDN) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("idCenso", idCenso);
  formData.append("tipoArquivoCenso", tipoArquivoCenso);
  formData.append("idArquivoAntigoCDN", idArquivoAntigoCDN);

  return axios.post(`${BASE_API}recadastramento/arquivo/upload`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const salvarArquivo = async (
  file,
  idCenso,
  tipoArquivoCenso,
  idArquivoAntigoCDN
) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("idCenso", idCenso);
    formData.append("tipoArquivoCenso", tipoArquivoCenso);
    formData.append("idArquivoAntigoCDN", idArquivoAntigoCDN); // Garante um valor válido

    const response = await axios.post(
      `${BASE_API}recadastramento/arquivo/upload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response;
  } catch (error) {
    console.error(
      "Erro ao enviar arquivo:",
      error.response ? error.response.data : error.message
    );
    return null;
  }
};

export default {
  obterCPF,
  getCenso,
  obterUF,
  obterCidades,
  obterPais,
  obterBairros,
  getEndereco,
  finalizar,
  obterRepresentante,
  obterHistorico,
  visualizar,
  retificar,
  getOpcoes,
  getParametros,
  getArquivo,
  formatarRequisicao,
  getComprovante,
  uploadArquivo,
  salvarArquivo,
};
